/* Stiluri generale pentru pagina de servicii */
.services {
    padding: 20px;
    font-family: 'Arial', sans-serif;
    background-color: #f7f5f5; /* Fundal crem */
    color: #333;
    background-image: url('./assets/marble.jpg'); /* Imaginea cu efect de marmură */
    background-size: cover; /* Ajustează pentru a acoperi întreaga pagină */
    background-repeat: no-repeat; /* Fundalul nu se va repeta */
    background-position: center; /* Centrarea imaginii */
    text-align: center;
}

.services h1 {
    font-size: 36px;
    color: #96765a; /* Culoare de accent */
    margin-bottom: 30px;
}

/* Container pentru serviciile de 2x2 */
.services-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); /* 2 coloane, cu lățimi minime de 300px */
    gap: 20px; /* Spațiu între itemi */
    justify-content: center;
}

/* Stiluri pentru itemii de serviciu */
.service-item {
    background-color: #fff;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    max-width: 100%; /* Asigură că itemii nu depășesc lățimea containerului */
    text-align: left;
}

/* Stiluri pentru imaginea serviciului */
/* Stiluri pentru imaginea serviciului */
.service-image {
    width: 100%; /* Asigură că imaginea ocupă întreaga lățime a containerului */
    height: 200px; /* Setează o înălțime fixă */
    object-fit: cover; /* Taie imaginea astfel încât să umple complet containerul */
    border-radius: 10px;
    margin-bottom: 15px;
}


/* Stiluri pentru titlul serviciului */
.service-title {
    font-size: 24px;
    color: #c8a47b;
    margin-bottom: 10px;
}

/* Stiluri pentru descrierea serviciului */
.service-description {
    font-size: 16px;
    line-height: 1.6;
    color: #555;
}
