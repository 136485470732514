/* Stiluri generale */
.home {
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    padding: 20px;
    font-family: 'Arial', sans-serif;
    color: #333;
    background-image: url('./assets/marble.jpg'); /* Imaginea cu efect de marmură */
    background-size: cover; /* Ajustează pentru a acoperi întreaga pagină */
    background-repeat: no-repeat; /* Fundalul nu se va repeta */
    background-position: center; /* Centrarea imaginii */
    text-align: center; /* Center text within .home */
    flex-wrap: wrap; /* Allow wrapping on smaller screens */
}

.home-image {
    display: block; /* Block level for centering */
    margin: 0 auto; /* Center horizontally with auto margins */
    max-width: 100%; /* Responsive width */
    height: auto; /* Maintain aspect ratio */
}

.card {
    background-color: #f7f5f5; /* Fundal alb-crem pentru card */
    border-radius: 15px;
    align-items: center; /* Center contents horizontally */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 30px;
    max-width: 1000px;
    margin: 20px auto;
    overflow: hidden;
    box-sizing: border-box; /* Include padding in width and height */
}

.column {
    max-width: 900px;
    margin: 0 auto;
    text-align: left;
}

h1 {
    text-align: center;
    font-size: 40px;
    color: #96765a; /* Schimbare de culoare pentru a se potrivi temei */
    margin-bottom: 20px;
}

h2 {
    font-size: 28px;
    color: #c8a47b; /* Schimbare de culoare pentru a se potrivi temei */
    margin-top: 30px;
}

p {
    font-size: 18px;
    line-height: 1.6;
    margin-bottom: 20px;
    color: #444; /* Text de culoare gri închis pentru contrast */
}

.home ul {
    font-size: 18px;
    line-height: 1.8;
    margin-left: 20px;
    padding-left: 20px;
    list-style: none; /* Elimină punctele implicite */
    color: #555; /* Culoare pentru text */
    border-left: 3px solid #c8a47b; /* Linie verticală colorată pe partea stângă */
    padding-left: 15px;
}

ul li {
    position: relative;
    padding-left: 25px;
    margin-bottom: 15px;
    transition: color 0.3s ease-in-out;
}

ul li:before {
    position: absolute;
    left: 0;
    top: 0;
    font-size: 24px;
    color: #96765a; /* Culoare pentru glif */
    transition: color 0.3s ease-in-out;
}

ul li:hover {
    color: #96765a; /* Culoare schimbată la hover pentru text */
}

ul li:hover:before {
    color: #c8a47b; /* Culoare schimbată la hover pentru glif */
}

/* Stiluri pentru carusel */
.carousel {
    position: relative;
    width: 100%;
    max-width: 800px;
    margin: 0 auto 40px auto;
    overflow: hidden;
}

.carousel-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.carousel-image {
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
    border-radius: 10px;
    opacity: 0.5; /* Imaginile laterale sunt estompate */
}

.carousel-image.large {
    width: 60%;
    height: auto;
    transform: scale(1);
    opacity: 1; /* Imaginea principală nu este estompată */
}

.carousel-image.small {
    width: 20%;
    height: auto;
    transform: scale(0.8); /* Imaginile laterale sunt mai mici */
    opacity: 0.5; /* Imaginile laterale sunt mai puțin proeminente */
    margin: 0 10px;
}

.carousel-btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    font-size: 30px;
    padding: 10px;
    cursor: pointer;
    border-radius: 50%;
    transition: background-color 0.3s;
}

.carousel-btn:hover {
    background-color: rgba(0, 0, 0, 0.8);
}

.carousel-btn.prev {
    left: 10px;
}

.carousel-btn.next {
    right: 10px;
}

/* Responsive Styling */
@media (max-width: 1200px) {
    .card {
        max-width: 800px;
        padding: 20px;
    }

    h1 {
        font-size: 36px;
    }

    h2 {
        font-size: 26px;
    }

    p, .home ul {
        font-size: 16px;
    }
}

@media (max-width: 992px) {
    .card {
        max-width: 600px;
        padding: 20px;
    }

    .carousel-image.large {
        width: 80%;
    }

    .carousel-image.small {
        width: 15%;
    }

    h1 {
        font-size: 32px;
    }

    h2 {
        font-size: 24px;
    }
}

@media (max-width: 768px) {
    .card {
        max-width: 90%;
        padding: 15px;
    }

    h1 {
        font-size: 28px;
    }

    h2 {
        font-size: 22px;
    }

    p, .home ul {
        font-size: 16px;
    }

    .carousel-image.large {
        width: 90%;
    }

    .carousel-image.small {
        width: 10%;
    }
}

@media (max-width: 576px) {
    .card {
        max-width: 95%;
        padding: 10px;
    }

    h1 {
        font-size: 24px;
    }

    h2 {
        font-size: 20px;
    }

    p, .home ul {
        font-size: 14px;
    }

    .carousel-image.large {
        width: 100%;
    }

    .carousel-image.small {
        display: none; /* Hide the smaller images on very small screens */
    }

    .carousel-btn {
        font-size: 20px;
        padding: 5px;
    }
}
