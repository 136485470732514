/* Navbar Styles */
.navbar {
    background-color: #333;
    padding: 10px;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 1000;
}

.navbar-logo {
    display: flex;
    align-items: center;
}

.logo-image {
    height: 50px;
    width: auto;
}
.hamburger {
    display: none; /* Hidden by default for larger screens */
    cursor: pointer;
    flex-direction: column;
    justify-content: space-between;
    width: 30px;
    height: 24px;
    position: relative;
}

.hamburger span {
    display: block;
    height: 3px;
    width: 100%;
    background-color: white;
    transition: all 0.4s ease;
}

/* Transform to X when active */
.hamburger.active span:nth-child(1) {
    transform: rotate(45deg);
    position: absolute;
    top: 50%;
    left: 0;
    transform-origin: center;
}
.hamburger.active span:nth-child(3) {
    transform: rotate(-45deg);
    position: absolute;
    top: 50%;
    left: 0;
    transform-origin: center;
}
.hamburger.active span:nth-child(2) {
    opacity: 0; 
}
.navbar-links {
    list-style: none;
    display: flex;
}

.navbar-links li {
    margin: 0 15px;
}

.navbar-links a {
    color: white;
    text-decoration: none;
}

/* Mobile Navbar */
.navbar-links-mobile {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 60px; /* Position just below the navbar */
    right: 0;  /* Align the menu on the right */
    width: 200px; /* Control the width of the dropdown */
    background-color: #333;
    padding: 10px 0;
}

.navbar-links-mobile li {
    text-align: center;
    margin: 10px 0;
}

.navbar-links-mobile a {
    padding: 10px 0;
    display: block;
    width: 100%;
    color: white;
    text-decoration: none;
}

/* Responsive Design */
@media (max-width: 768px) {
    .hamburger {
        display: flex; /* Show hamburger on small screens */
    }

    .navbar-links {
        display: none; /* Hide the standard navbar links */
    }

    .navbar-links-mobile.active {
        display: flex; /* Show mobile navbar when active */
    }
}
