/* Contact Section with Marble Background */
.contact-container {
    background-image: url('./assets/marble.jpg'); /* Marble background image */
    background-size: cover; /* Cover the entire page */
    background-repeat: no-repeat; /* No repetition of the background image */
    background-position: center; /* Center the background image */
    padding: 40px;
    min-height: 100vh; /* Ensure full height */
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Flex container to align both sections */
.contact-content {
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: stretch; /* Center vertically */
    max-width: 1200px; /* Controls overall width */
    width: 100%;
    gap: 20px;
    flex-wrap: wrap; /* Wrap the content on smaller screens */
}

/* Contact Form Section */
.contact-left, .contact-right {
    flex: 1;
    background-color: #FFFFFF;
    padding: 30px;
    border-radius: 12px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    justify-content: center; /* Center content within each section */
    align-items: center; /* Center content horizontally */
    text-align: center; /* Ensure text is centered */
    max-width: 100%; /* Ensure both sections take up full width on smaller screens */
}

/* Ensuring both sections are responsive */
.contact-left {
    max-width: 45%; /* Adjust width for larger screens */
}

.contact-right {
    max-width: 55%; /* Adjust width for larger screens */
}

/* Responsive adjustment for smaller screens */
@media (max-width: 768px) {
    .contact-left, .contact-right {
        max-width: 100%; /* Make both sections full width on small screens */
    }
}

/* Maps Styling */
.map-container {
    margin-top: 20px;
    width: 100%; /* Make maps responsive */
}

.map-container h3 {
    margin-bottom: 10px;
}

/* Logo Styling */
.home-image {
    max-width: 50%;
    height: auto;
    margin: 20px auto;
    display: block;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Contact Text Styling */
.contact h1 {
    color: #603F26;
    font-size: 2.5em;
    margin-bottom: 10px;
}

.contact p {
    color: #6C4E31;
    font-size: 1.2em;
    margin-bottom: 20px;
}

/* Contact Info Styling */
.contact-info {
    margin-bottom: 20px;
    text-align: center; /* Ensure text is centered */
}
