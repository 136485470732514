/* Stiluri generale pentru pagina de produse */
.products {
    padding: 20px;
    font-family: 'Arial', sans-serif;
    background-color: #f7f5f5; /* Fundal crem similar cu pagina de servicii */
    color: #333;
    background-image: url('./assets/marble.jpg'); /* Imaginea cu efect de marmură */
    background-size: cover; /* Ajustează pentru a acoperi întreaga pagină */
    background-repeat: no-repeat; /* Fundalul nu se va repeta */
    background-position: center; /* Centrarea imaginii */
    background-attachment: fixed;
    text-align: center;
}

.products h1 {
    font-size: 36px;
    color: #96765a; /* Culoare de accent */
    margin-bottom: 30px;
}

/* Stiluri pentru carduri */
.card-container {
    margin-bottom: 40px;
}

.cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.product-card, .collection-card {
    background-color: #fff; /* Fundal alb pentru carduri */
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 15px; /* Added margin to increase space around each card */
    padding: 20px;
    width: 300px; /* Fixed width for uniform size */
    text-align: center; /* Centrează textul în card */
    transition: all 0.3s ease; /* Smooth transition for expansion */
    overflow: hidden;
}

.product-card img, .collection-card img {
    width: 100%; /* Asigură că imaginea ocupă întreaga lățime a containerului */
    height: 200px; /* Setează o înălțime fixă */
    object-fit: cover; /* Taie imaginea astfel încât să umple complet containerul */
    border-radius: 10px;
    margin-bottom: 15px;
}

.product-card h3, .collection-card h3 {
    font-size: 24px;
    color: #c8a47b; /* Culoare de accent pentru titluri */
    margin-bottom: 10px;
}

.product-card ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

/* Stiluri pentru carduri */
.product-card li {
    font-size: 16px;
    margin-bottom: 10px;
    color: #555;
    cursor: pointer;
    position: relative; /* For positioning item details */
}
/* Arrow icon styles */
.product-card li {
    position: relative;
    padding-right: 30px; /* Space for the arrow */
}

.product-card li::after {
    content: '';
    position: absolute;
    right: 10px; /* Position from the right */
    top: 50%;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid #555; /* Arrow color */
    transition: transform 0.3s ease; /* Smooth transition */
}

/* When the item is expanded */
.product-card li.expanded::after {
    transform: translateY(-50%) rotate(180deg); /* Rotate arrow */
}

/* Stiluri pentru detalii */
.item-details {
    overflow: hidden;
    transition: max-height 0.5s ease; /* Smooth transition for expanding */
    padding: 10px 15px; /* Add padding for better visibility */
    background-color: #f5f5f5; /* Slightly different background for details */
    border-radius: 10px; /* Match card radius */
    color: #666;
    margin-top: 10px; /* Add spacing between item name and details */
}

/* When the item is expanded */
.product-card li.expanded .item-details {
    max-height: 300px; /* Adjust based on content length */
}

.item-details p {
    margin: 0;
}

/* When the item is expanded */
.product-card .item-details-expanded {
    max-height: 200px; /* Adjust based on content length */
}

/* Stiluri pentru imagini în colecții */
.collection-images {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
}

.collection-image {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 10px;
    cursor: pointer;
    transition: transform 0.3s ease;
}

.collection-image:hover {
    transform: scale(1.05);
}

/* Stiluri pentru modal */
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.modal-content {
    position: relative;
    max-width: 80%;
    max-height: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal-image {
    max-width: 80%;
    max-height: 80%;
    border-radius: 10px;
}

.modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 30px;
    color: white;
    cursor: pointer;
    transition: color 0.3s ease;
}

.modal-close:hover {
    color: #c8a47b;
}

.modal-nav {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 30px;
    color: white;
    cursor: pointer;
    transition: color 0.3s ease;
}

.modal-nav:hover {
    color: #c8a47b;
}

.modal-prev {
    left: 10px;
}

.modal-next {
    right: 10px;
}
