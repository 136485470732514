/* Stiluri generale pentru pagina de proiecte */
.projects {
    padding: 20px;
    font-family: 'Arial', sans-serif;
    background-color: #f7f5f5;
    background-image: url('./assets/marble.jpg'); /* Imaginea cu efect de marmură */
    background-position: center; /* Centrarea imaginii */
    text-align: center;
}

.projects h1 {
    font-size: 36px;
    color: #96765a;
    margin-bottom: 30px;
}

/* Stiluri pentru galeria de imagini */
.gallery {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 15px;
}

.project-image {
    width: 300px;
    height: 200px;
    object-fit: cover;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: transform 0.3s ease;
}

.project-image:hover {
    transform: scale(1.05);
}

/* Stiluri pentru modal */
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    pointer-events: none; /* Dezactivăm clicurile pe fundal */
}

.modal-content {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: auto; /* Permitem clicuri pe imagine și săgeți */
}

/* Păstrăm dimensiunea originală a imaginii */
.modal-image {
    max-width: 80%;
    max-height:80%;
    object-fit: contain; /* Imaginea își păstrează proporțiile și nu este deformată */
    border-radius: 10px;
}

/* Stiluri pentru butonul de închidere */
.modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 30px;
    color: white;
    cursor: pointer;
    transition: color 0.3s ease;
}

.modal-close:hover {
    color: #c8a47b;
}

/* Stiluri pentru navigarea prin imagini */
.modal-nav {
    position: fixed; /* Poziționat fix pentru a nu se mișca */
    top: 50%;
    transform: translateY(-50%);
    font-size: 50px; /* Săgeți mai mari */
    color: white;
    cursor: pointer;
    transition: color 0.3s ease;
    z-index: 1001; /* Deasupra imaginii */
}

.modal-nav:hover {
    color: #c8a47b;
}

.modal-prev {
    left: 10px; /* Poziționarea fixă, în afara imaginii */
}

.modal-next {
    right: 10px; /* Poziționarea fixă, în afara imaginii */
}
