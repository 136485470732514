.contact-form {
    background-color: #FFFFFF; /* White background for the form */
    padding: 20px 40px; /* Increased horizontal padding */
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin: 0 auto;
    max-width: 400px; /* Increased max-width for a wider form */
    width: auto; /* Ensure the form takes full width up to the max-width */
}

.form-group {
    display: flex;
    flex-direction: column;
}

.form-group label {
    color: #6C4E31; /* Label color */
    font-size: 1.1em;
    margin-bottom: 5px;
}

.success-message {
    color: #007F73; /* Green for success */
    font-size: 1.1em;
    margin-top: 10px;
}

.error-message {
    color: #F44336; /* Red for errors */
    font-size: 1.1em;
    margin-top: 10px;
}

.form-group input,
.form-group textarea {
    padding: 12px; /* Increased padding for larger inputs */
    border: 1px solid #6C4E31; /* Border color for inputs */
    border-radius: 4px;
    font-size: 1.1em; /* Slightly larger text size for inputs */
    color: #333; /* Text color inside inputs */
}

.form-group input:focus,
.form-group textarea:focus {
    border-color: #6C4E31; /* Border color on focus */
    outline: none;
}

.submit-button {
    background-color: #6C4E31; /* Button background color */
    color: white;
    border: none;
    padding: 14px 24px; /* Slightly larger button */
    border-radius: 6px;
    font-size: 1.2em; /* Larger font for the button */
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.submit-button:hover {
    background-color: #004d40; /* Darker shade on hover */
}
