/* src/components/Footer.css */

.footer {
    padding: 20px;
    background-color: #1a1a1a; /* Darker background for a more elegant look */
    color: #f5f5f5; /* Light grey text for contrast */
    text-align: center;
    font-size: 0.9em; /* Slightly smaller font size for a cleaner look */
}

.footer p {
    margin: 0; /* Remove default margin for the paragraph */
    color: #b0b0b0; /* Lighter grey color for the copyright text */
}

.footer-social {
    margin-top: 10px;
}

.footer-social a {
    margin: 0 15px; /* Increase spacing between social links */
    color: #f5f5f5; /* Light grey color for links */
    text-decoration: none;
    font-size: 1.1em; /* Slightly larger font size for better visibility */
    transition: color 0.3s ease, transform 0.2s ease; /* Smooth transitions for hover effects */
}

.footer-social a:hover {
    color: #007BFF; /* Elegant blue for hover effect */
    transform: scale(1.1); /* Slight zoom effect on hover */
}
